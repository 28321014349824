@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url('./font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('./font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url('./font/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('./font/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url('./font/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'UnifrakturMaguntia-Regular';
  src: local('UnifrakturMaguntia-Regular'),
    url('./font/UnifrakturMaguntia-Regular.ttf') format('truetype');
}

.body {
  margin: 0px;
  font-family: Poppins-Light;
}
